import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizQuestion1', 'quizQuestion2', 'quizQuestion3', 'quizQuestion4', 'quizQuestion5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const GoTo = makeShortcode("GoTo");
const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quiz"
    }}>{`Quiz`}</h1>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion1" once={true} question="QUESTION 01: Even though my child is now a teenager, I should remain involved in their life." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Being involved in your teenager's life in a balanced way helps them feel
        cared for and it also enables you to notice if they are experiencing any problems. See{' '}
        <GoTo to="/m4-nurture/02-balancing-act" mdxType="GoTo">Finding a balanced way to be involved in my teenager's life</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion2" once={true} question="QUESTION 02: Who my teenager's friends are is none of my business." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Knowing who your teenager is friends with is very important. Knowing who
        the friends are and what they do together helps you understand the influences in your teen's life. See{' '}
        <GoTo to="/m4-nurture/04-know-your-teen" mdxType="GoTo">How well do you know your teenager</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion3" once={true} question="QUESTION 03: Encouraging independence in my teenager is important." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Gradually increasing your teenager's responsibilities and independence over
        time, allows them to mature and build their self-confidence. See{' '}
        <GoTo to="/m4-nurture/07-encourage-independence" mdxType="GoTo">Encourage independence</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion4" once={true} question="QUESTION 04: I should be putting my teenager's dirty clothes in the laundry basket." fallbackResponse={<p>
        The correct answer is: <b>False</b>. Your teenager is capable of putting their dirty clothes in the laundry
        basket. Encourage your teenager to help around the house as this will build their sense of responsibility and
        independence. See <GoTo to="/m4-nurture/08-responsibilities" mdxType="GoTo">What should my teenager be doing</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: false
      }, {
        name: 'False',
        correct: true
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizQuestion5" once={true} question="QUESTION 05: To help my teenager's confidence, I should encourage them to try a variety of activities." fallbackResponse={<p>
        The correct answer is: <b>True</b>. Helping your teenager try a number of different activities and interests
        will help boost their self-esteem and confidence. It will also help them find out what they are interested in
        and what they are good at. See{' '}
        <GoTo to="/m4-nurture/09-other-activities" mdxType="GoTo">What else could my teenager be doing</GoTo>.
      </p>} answers={[{
        name: 'True',
        correct: true
      }, {
        name: 'False',
        correct: false
      }, {
        name: "Don't Know",
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <SingleImage gridSize={4} smallGridSize={8} src="/images/shared/51.1-01.svg" alt="thinking woman" mdxType="SingleImage" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      